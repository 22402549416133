const React =  require('react');
const Layout = require("./src/components/layouts/Layout").default;

require('bootstrap/dist/css/bootstrap.min.css');
require("./src/styles/global.css");
require("./src/styles/override.css");
require("./src/styles/ui-elements.css");

exports.wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}